import axios from "axios";
import { React, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Utils from "../../Utils";
import { Toast } from "primereact/toast";

export default function AddEditProducts() {
  const toast = useRef(null);
  const location = useLocation();

  const [departmentsArray, setDepartmentsArray] = useState([]);
  const [groupsArray, setGroupsArray] = useState([]);
  const [subdepartmentsArray, setSubDepartmentsArray] = useState([]);
  const [filteredSubDepartmentArray, setFilteredSubDepartmentArray] = useState(
    []
  );
  const [
    filteredOnlineSubDepartmentArray,
    setFilteredOnlineSubDepartmentArray,
  ] = useState([]);
  const [shopsArray, setShopsArray] = useState([]);
  const [shopsPricesArray, setShopsPricesArray] = useState([]);
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editProductID, setEditProductID] = useState(0);

  const [formData, setFormData] = useState({
    ProductBarcode: "",
    ProductCaseBarcode: "",
    ProductPLU: "",
    ProductType: 1,
    Department: "",
    SubDepartment: "0",
    ProductName: "",
    ProductDescription: "",
    ProductSize: "",
    BrandName: "",
    VAT: 1,
    VATAmount: 0.0,
    ProductUnitPerCase: "0",
    ProductCostPerCase: "0",
    ProductCost: "0",
    ProductMargin: "0",
    ProductSellingPrice: "0",
    ProductNightPrice: "0",
    ProductMinOrder: 1,
    ProductLowStockAlertQty: 1,
    ProductMinStockLevel: 1,
    WarehouseQTY: 1,
    AgeLimit: 99,
    ExpiryDate: "",
    ExtraBarcode: "",
    OnlineProductName: "",
    OnlineDepartment: "",
    OnlineSubDepartment: "",
    OnlineVAT: "",
    OnlinePrice: "0",
    IsAllowDiscount: "1",
    IsPrintToSecondPrinter: "1",
    Group: 0,
    ShopPrices: shopsPricesArray,
  });

  const getDepartments = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "departments/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        //console.log("dept loaded");
        setDepartmentsArray(response.data.departments);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getAllSubDepartments = () => {
    //console.log("getAllSubDepartments()");
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "subdepartments/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setSubDepartmentsArray(response.data.sub_departments);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getSubDepartmentsForDepartment = () => {
    setFilteredSubDepartmentArray([]);
    //getAllSubDepartments();
    //console.log("subdepartmentsArray", subdepartmentsArray);
    var filteredSubDepartmentArray = subdepartmentsArray.filter(
      (item) => item.department_id === parseInt(formData.Department)
    );

    setFilteredSubDepartmentArray(filteredSubDepartmentArray);
  };
  const getGroups = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "products/getallgroups",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setGroupsArray(response.data.groups);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getShopsPricesArrayForAdd = (price) => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "shops/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Shops array ", response.data.shops);

        var localShopPricesArray = [];
        response.data.shops.map((item, index) => {
          var itemShopPrice = {
            ArrayIndex: index,
            ShopID: item.id,
            ShopName: item.shop_name,
            Force: false,
            SellingPrice: price,
            NightPrice: price,
            QTY: 0,
            MinStockLevel: 0,
            MinOrderQTY: 0,
          };

          localShopPricesArray.push(itemShopPrice);
          return 0;
        });

        console.log("localShopPricesArray in add ", localShopPricesArray);
        setShopsPricesArray(localShopPricesArray);
        //setShopsArray(response.data.shops);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };
  const getOnlineSubDepartmentsForDepartment = () => {
    setFilteredOnlineSubDepartmentArray([]);
    var filteredOnlineSubDepartmentArray = subdepartmentsArray.filter(
      (item) => item.department_id === parseInt(formData.OnlineDepartment)
    );

    setFilteredOnlineSubDepartmentArray(filteredOnlineSubDepartmentArray);
  };

  const calculateMargin = (e) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      updatedData.ProductMargin = (
        ((parseFloat("0" + updatedData.ProductSellingPrice) -
          parseFloat("0" + updatedData.ProductCost)) /
          parseFloat("0" + updatedData.ProductSellingPrice)) *
        100
      ).toFixed(2);

      return updatedData;
    });
  };

  const calculateProductCost = () => {
    if (formData.ProductCostPerCase === 0 || formData.ProductUnitPerCase === 0)
      return;

    setFormData((previousData) => {
      const updatedData = { ...previousData };
      updatedData.ProductCost = (
        parseFloat(updatedData.ProductCostPerCase) /
        parseFloat(updatedData.ProductUnitPerCase)
      ).toFixed(2);

      if (
        updatedData.ProductCostPerCase === 0 ||
        updatedData.ProductUnitPerCase === 0
      )
        return;

      updatedData.ProductCost = isNaN(updatedData.VATAmount)
        ? updatedData.ProductCost + 0
        : parseFloat(updatedData.ProductCost) +
          (parseFloat(updatedData.ProductCost) / 100) *
            parseFloat(updatedData.VATAmount);

      updatedData.ProductCost = parseFloat(updatedData.ProductCost).toFixed(2);
      return updatedData;
    });

    if (parseFloat(formData.ProductMargin) > 0) calculateMargin();
  };

  const handleChange = (e) => {
    //console.log("formData." + e.target.name, e.target.value);
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  const handleShopPriceChange = (e, index) => {
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    updateShopPriceRowData(index, e.target.name, inputValue);
  };

  const updateShopPriceRowData = (index, key, inputValue) => {
    //console.log(key, index, inputValue);

    let localArray = shopsPricesArray.map((item) => {
      if (item.ArrayIndex === index) {
        item[key] = inputValue;
      }
      return item;
    });
    setShopsPricesArray(localArray);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var apiURL = "";
    if (
      location != null &&
      location.action != null &&
      location.action === "edit"
    ) {
      apiURL = Utils.apiBaseURL + "products/edit";
    } else {
      apiURL = Utils.apiBaseURL + "products/add";
    }

    var localFormData = formData;
    localFormData["ShopPrices"] = shopsPricesArray;

    console.log("localFormData", localFormData);

    setLoading(true);

    axios
      .post(
        apiURL,
        { formData: localFormData, product_id: editProductID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        //console.log("API response", response);
        if (
          response.data.status != null &&
          response.data.status === "success"
        ) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.msg,
            life: 3000,
          });

          if (editProductID === 0) {
            setTimeout(window.location.reload(false), 7000);
          }
          setLoading(false);
        } else {
          alert(response.data.error);
        }
      })
      .catch((ex) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: ex.message,
          life: 3000,
        });
      });
  };

  const handleGetOnlinePricing = (e) => {
    setLoading(true);

    if (formData.ProductBarcode === "") {
      toast("Product Barcode is required!!");

      return;
    }
    axios
      .post(
        Utils.apiBaseURL + "services/getonlineprice",
        { shop_id: "UK5962", barcode: "8901725121716" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        //console.log("API response", response);
        if (response.data.status != null) {
          setFormData((prevalue) => {
            return {
              ...prevalue,
              OnlineProductName: response.data.data.PRODUCT_NAME,
              ProductName: response.data.data.PRODUCT_NAME,
              OnlineDepartment: response.data.data.DEPARTMENT_NAME,
              Department: response.data.data.DEPARTMENT_NAME,
              OnlineVAT: response.data.data.VAT_AMOUNT,
              VATAmount: response.data.data.VAT_AMOUNT,
              OnlineSubDepartment: response.data.data.SUB_DEPARTMENT_NAME,
              SubDepartment: response.data.data.SUB_DEPARTMENT_NAME,
              OnlinePrice: response.data.data.PRODUCT_PRICE,
              SellingPrice: response.data.data.PRODUCT_PRICE,
            };
          });
          setLoading(false);
        } else {
          toast(response.data.error);
        }
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getProductForEdit = (_id) => {
    if (_id === 0) return;
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "products/getbyid",
        { product_id: _id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Products loaded", response);
        var localShopPricesArray = [];
        response.data.shopPrices.map((item, index) => {
          var itemShopPrice = {
            ArrayIndex: index,
            ShopID: item.shop_id,
            ShopName: item.shop_name,
            Force: false,
            SellingPrice: item.product_selling_price,
            NightPrice: item.product_night_price,
            QTY: item.product_qty ?? 0,
            MinStockLevel: item.min_stock_level ?? 1.0,
            MinOrderQTY: item.min_order_qty ?? 1.0,
          };

          localShopPricesArray.push(itemShopPrice);
          return 0;
        });
        var localProduct = {
          ProductCaseBarcode: response.data.product.product_case_barcode,
          ProductBarcode: response.data.product.product_barcode,
          ProductPLU: response.data.product.product_plu,
          ProductType: response.data.product.product_type_id,
          Department: response.data.product.department_id,
          SubDepartment: response.data.product.sub_department_id,
          ProductName: response.data.product.product_name,
          ProductDescription: response.data.product.product_description,
          ProductSize: response.data.product.product_size,
          BrandName: response.data.product.brand_name,
          VAT: response.data.product.vat_id,
          ProductUnitPerCase: response.data.product.product_unit_per_case,
          ProductCostPerCase: response.data.product.product_cost_per_case,
          ProductCost: response.data.product.product_cost,
          ProductSellingPrice: response.data.product.product_selling_price,
          ProductNightPrice: response.data.product.product_night_price,
          ProductMinOrder: response.data.product.product_min_order,
          ProductLowStockAlertQty:
            response.data.product.product_low_stock_alert_qty,
          ProductMinStockLevel: response.data.product.product_min_stock_level,
          WarehouseQTY: response.data.product.warehouse_qty,
          AgeLimit: response.data.product.age_limit,
          ExpiryDate: response.data.product.expiry_date,
          ExtraBarcode: "",
          OnlineProductName: "",
          OnlineDepartment: "",
          OnlineSubDepartment: "",
          OnlineVAT: "",
          OnlinePrice: "",
          Group: response.data.product.group_id,
          ShopPrices: localShopPricesArray,
        };
        setFormData(localProduct);
        setShopsPricesArray(localShopPricesArray);

        calculateMargin();
        console.log("localShopPricesArray", localShopPricesArray);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const getSupplierProductPrice = (_id) => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "products/supplierproducts",
        { product_id: _id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);

        setSupplierProducts(response.data.supplierproducts);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  //executed first
  useEffect(() => {
    // console.log("location.rowData", location.rowData);
    // console.log("location.action", location.action);

    getDepartments();
    getAllSubDepartments();

    getGroups();

    //for edit mode
    if (
      location != null &&
      location.action != null &&
      location.rowData != null &&
      parseInt(location.rowData.id)
    ) {
      setEditProductID(location.rowData.id);
      getSupplierProductPrice(location.rowData.id);
    } else {
      //get all shops prices in add mode
      getShopsPricesArrayForAdd(0);
    }
  }, []);

  useEffect(() => {
    getProductForEdit(editProductID);
  }, [subdepartmentsArray]);

  // useEffect(() => {
  //   if (editProductID > 0) {
  //     console.log("editProductID ", editProductID);
  //     getProductForEdit(editProductID);
  //   }
  // }, [editProductID]);

  useEffect(() => {
    if (formData.Department != null && formData.Department !== "") {
      //console.log("loading sub-Departments for " + formData.Department);
      getSubDepartmentsForDepartment();
    } else {
      setFilteredSubDepartmentArray([]);
    }
  }, [formData.Department]);

  useEffect(() => {
    setFilteredOnlineSubDepartmentArray([]);
    if (formData.OnlineDepartment != null && formData.OnlineDepartment !== "") {
      getOnlineSubDepartmentsForDepartment();
    } else {
      setFilteredOnlineSubDepartmentArray([]);
    }
  }, [formData.OnlineDepartment]);

  useEffect(() => {
    console.log("formData :", formData);
  }, [formData]);

  return (
    <>
      <Toast ref={toast} />
      {/* <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1> Product Information</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  {editProductID > 0 ? "Edit" : "Add"} Product
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section> */}
      <form onSubmit={handleSubmit}>
        <section className="content mt-2">
          <div className="container-fluid">
            <div className="row g-1">
              <div className="col-6">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Product Information</h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group ">
                            <label>Type *</label>
                            <select
                              className="form-control form-control-sm"
                              name="ProductType"
                              value={formData.ProductType}
                              onChange={handleChange}
                              required
                            >
                              <option value={1}>Counted with Barcode</option>
                              <option value={2}>Weighted Products</option>
                              <option value={3}>Counted without Barcode</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>
                              Barcode
                              {parseInt(formData.ProductType) === 1 ? " *" : ""}
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="ProductBarcode"
                              value={formData.ProductBarcode}
                              onChange={handleChange}
                              required={parseInt(formData.ProductType) === 1}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label> Case Barcode</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="ProductCaseBarcode"
                              value={formData.ProductCaseBarcode}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>PLU</label>
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="ProductPLU"
                              value={formData.ProductPLU}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Department *</label>
                            <select
                              className="form-control form-control-sm"
                              name="Department"
                              value={formData.Department}
                              onChange={handleChange}
                              required
                            >
                              <option value={""}>--SELECT--</option>
                              {departmentsArray.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.department_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Sub Department</label>
                            <select
                              className="form-control form-control-sm"
                              name="SubDepartment"
                              value={formData.SubDepartment}
                              onChange={handleChange}
                            >
                              <option value={"0"}>--SELECT--</option>
                              {filteredSubDepartmentArray.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.sub_department_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputRounded0">Name *</label>
                        <input
                          type="text"
                          className="form-control form-control-sm rounded-0"
                          name="ProductName"
                          value={formData.ProductName}
                          onChange={(e) => {
                            handleChange(e);
                            console.log(e.target.value);
                          }}
                          required
                          onBlur={(e) => {
                            setFormData({
                              ...formData,
                              ProductDescription: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label>Description</label>
                        <input
                          className="form-control form-control-sm"
                          rows="3"
                          name="ProductDescription"
                          value={formData.ProductDescription}
                          onChange={handleChange}
                        ></input>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">Size *</label>
                            <input
                              type="text"
                              className="form-control form-control-sm rounded-0"
                              name="ProductSize"
                              value={formData.ProductSize}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">
                              Brand Name
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-sm rounded-0"
                              name="BrandName"
                              value={formData.BrandName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Headoffice Group</label>
                            <select
                              className="form-control form-control-sm"
                              name="Group"
                              value={formData.Group}
                              onChange={handleChange}
                            >
                              <option value="0">--SELECT--</option>
                              {groupsArray.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {/*    <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Stock/Extra Information</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Headoffice Group</label>
                      <select
                        className="form-control form-control-sm"
                        name="Group"
                        value={formData.Group}
                        onChange={handleChange}
                      >
                        <option value="0">--SELECT--</option>
                        {groupsArray.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.group_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Product Min Order
                          </label>
                          <input
                            type="number"
                            min="0.00"
                            step="1"
                            max="10000"
                            className="form-control form-control-sm rounded-0"
                            name="ProductMinOrder"
                            value={formData.ProductMinOrder}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Product Low Stock Alert Qty
                          </label>
                          <input
                            type="number"
                            min="0.00"
                            step="1"
                            max="10000"
                            className="form-control form-control-sm rounded-0"
                            name="ProductLowStockAlertQty"
                            value={formData.ProductLowStockAlertQty}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Product Min Stock Level
                          </label>
                          <input
                            type="number"
                            min="0.00"
                            step="1"
                            max="10000"
                            className="form-control form-control-sm rounded-0"
                            name="ProductMinStockLevel"
                            value={formData.ProductMinStockLevel}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Warehouse QTY
                          </label>
                          <input
                            type="number"
                            min="0.00"
                            step="1"
                            max="10000"
                            className="form-control form-control-sm rounded-0"
                            name="WarehouseQTY"
                            value={formData.WarehouseQTY}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="AgeLimit">Age Limit</label>
                          <select
                            className="form-control form-control-sm"
                            name="AgeLimit"
                            value={formData.AgeLimit}
                            onChange={handleChange}
                            required
                          >
                            <option value={99}>99</option>
                            <option value={18}>18</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="exampleInputRounded0">Expiry Date</label>
                        <input
                          type="text"
                          className="form-control form-control-sm rounded-0"
                          id="exampleInputRounded0"
                        />
                      </div> 
                        <label htmlFor="exampleInputRounded0">
                          Expiry Date
                        </label>
                        <div className="input-group">
                          <Calendar
                            id="icon"
                            name="ExpiryDate"
                            value={formData.ExpiryDate}
                            onChange={handleChange}
                            defaultValue={new Date()}
                            dateFormat="yy-mm-dd"
                            showIcon
                          />
                       <div className="input-group-prepend">
                          <span className="input-group-text">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-calendar3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                              <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                            </svg>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          data-inputmask-alias="datetime"
                          data-inputmask-inputformat="dd/mm/yyyy"
                          data-mask
                          name="ExpiryDate"
                          value={formData.ExpiryDate}
                          onChange={handleChange}
                        /> 
                        </div>
                      </div>
                    </div>

                    <div className="row pt-2">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="IsAllowDiscount"
                              name="IsAllowDiscount"
                              value={formData.IsAllowDiscount}
                              defaultValue="false"
                              onChange={handleChange}
                              defaultChecked="false"
                            />
                            <label
                              htmlFor="IsAllowDiscount"
                              className="custom-control-label"
                            >
                              Allow Discount
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="IsPrintToSecondPrinter"
                              name="IsPrintToSecondPrinter"
                              value={formData.IsPrintToSecondPrinter}
                              defaultValue="false"
                              onChange={handleChange}
                              defaultChecked="false"
                            />
                            <label
                              htmlFor="IsPrintToSecondPrinter"
                              className="custom-control-label"
                            >
                              Print To Second Printer
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  */}

                  <div className="card card-primary d-none">
                    <div className="card-header">
                      <h3 className="card-title">
                        Extra Barcode (Duplicate barcodes will not be saved)
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="exampleInputRounded0">
                            Extra Barcode
                          </label>
                          {/*    {arr.map((item, i) => {
                        return (
                          <>
                            <input
                              onChange={handleChange}
                              className="form-control form-control-sm rounded-0 "
                              value={item.value}
                              id={i}
                              type={item.type}
                              size="40"
                            />
                            <button
                              onClick={addInput}
                              className="btn btn-danger "
                            >
                              Delete
                            </button>
                          </>
                        );
                      })}

                      <div className="row pt-4">
                        <div className="col-sm-4">
                          <button
                            onClick={addInput}
                            className="btn btn-primary "
                          >
                            Add
                          </button>
                        </div>
                        <div className="col-sm-4"></div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">
                        Cost/Selling Price Information
                      </h3>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="exampleSelectBorder">VAT*</label>
                            <select
                              className=" form-control form-control-sm"
                              name="VAT"
                              value={formData.VAT}
                              onChange={(e) => {
                                //handleChange(e);

                                setFormData({
                                  ...formData,
                                  VAT: e.target.value,
                                  VATAmount:
                                    e.target.options[e.target.selectedIndex]
                                      .text,
                                });
                                calculateProductCost();
                              }}
                            >
                              <option value={1}>0.00</option>
                              <option value={2}>5.00</option>
                              <option value={3}>20.00</option>
                              <option value={4}>NA</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">
                              Unit Per Case *
                            </label>
                            <input
                              type="number"
                              min="1"
                              step="1"
                              max="10000"
                              inputMode="decimal"
                              className="form-control form-control-sm rounded-0"
                              name="ProductUnitPerCase"
                              value={formData.ProductUnitPerCase}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">
                              Cost Per Case*
                            </label>
                            <input
                              type="number"
                              min="0.01"
                              step="0.01"
                              max="10000"
                              inputMode="decimal"
                              className="form-control form-control-sm rounded-0"
                              name="ProductCostPerCase"
                              value={formData.ProductCostPerCase}
                              onChange={(e) => {
                                handleChange(e);
                                calculateProductCost();
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">
                              Selling Price*
                            </label>
                            <input
                              type="number"
                              min="0.00"
                              step="0.01"
                              max="10000"
                              inputMode="decimal"
                              className="form-control form-control-sm rounded-0"
                              name="ProductSellingPrice"
                              value={formData.ProductSellingPrice}
                              onChange={(e) => {
                                handleChange(e);
                                calculateMargin();
                              }}
                              onBlur={(e) => {
                                setFormData({
                                  ...formData,
                                  ProductNightPrice: e.target.value,
                                });

                                if (location.rowData == null) {
                                  getShopsPricesArrayForAdd(e.target.value);
                                }
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">
                              Night Price*
                            </label>
                            <input
                              type="number"
                              min="0.00"
                              step="0.01"
                              max="10000"
                              inputMode="decimal"
                              className="form-control form-control-sm rounded-0"
                              name="ProductNightPrice"
                              value={formData.ProductNightPrice}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">Cost*</label>
                            <input
                              type="number"
                              min="0.00"
                              step="0.01"
                              max="10000"
                              inputMode="decimal"
                              className="form-control form-control-sm rounded-0"
                              name="ProductCost"
                              value={formData.ProductCost}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputRounded0">
                              Margin*
                            </label>
                            <input
                              type="number"
                              min="0.00"
                              step="0.01"
                              max="10000"
                              inputMode="decimal"
                              className="form-control form-control-sm rounded-0"
                              name="ProductMargin"
                              value={formData.ProductMargin}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*   <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Online Price</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Product Name</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="OnlineProductName"
                            value={formData.OnlineProductName}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Department</label>
                          <select
                            className="form-control form-control-sm"
                            name="OnlineDepartment"
                            value={formData.OnlineDepartment}
                            onChange={handleChange}
                            disabled
                          >
                            <option value={""}>--SELECT--</option>
                            {departmentsArray.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.department_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Sub-Department</label>
                          <select
                            className="form-control form-control-sm"
                            name="OnlineSubDepartment"
                            value={formData.OnlineSubDepartment}
                            onChange={handleChange}
                            disabled
                          >
                            <option value={""}>--SELECT--</option>
                            {filteredOnlineSubDepartmentArray.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.sub_department_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>VAT</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="OnlineVAT"
                            value={formData.OnlineVAT}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Price</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="OnlinePrice"
                            value={formData.OnlinePrice}
                            onChange={handleChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          type="button"
                          name="btnGetOnlinePricing"
                          value="Use This"
                          className="btn btn-success"
                          onClick={handleGetOnlinePricing}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="col-6">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Shop Prices</h3>
                    </div>
                    <div className="card-body">
                      <div className="card">
                        <div className="card-body p-0">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Shop</th>
                                {/* <th>Force</th> */}
                                <th>Price </th>
                                <th>Night Price </th>
                                <th>Stock</th>
                                {/* <th>Shop Min Stock Level </th>
                                <th>Shop Min Order QTY </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {shopsPricesArray.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.ShopName}</td>
                                    {/* <td>
                                    <div className="custom-control custom-checkbox ">
                                      <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="Force"
                                        name="Force"
                                        defaultChecked="false"
                                        checked={
                                          isNaN(shopsPricesArray[index].Force)
                                            ? false
                                            : shopsPricesArray[index].Force
                                        }
                                        value={
                                          isNaN(shopsPricesArray[index].Force)
                                            ? 0
                                            : shopsPricesArray[index].Force
                                        }
                                      />
                                      <label
                                        htmlFor="Force"
                                        className="custom-control-label"
                                        width=""
                                      ></label>
                                    </div>
                                  </td> */}
                                    <td>
                                      <input
                                        type="number"
                                        min="0.00"
                                        step="0.01"
                                        max="10000"
                                        inputMode="decimal"
                                        className="form-control form-control-sm"
                                        name="SellingPrice"
                                        value={
                                          isNaN(
                                            shopsPricesArray[index].SellingPrice
                                          )
                                            ? 0
                                            : shopsPricesArray[index]
                                                .SellingPrice
                                        }
                                        onChange={(e) =>
                                          handleShopPriceChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min="0.00"
                                        step="0.01"
                                        max="10000"
                                        inputMode="decimal"
                                        className="form-control form-control-sm"
                                        name="NightPrice"
                                        value={
                                          isNaN(
                                            shopsPricesArray[index].NightPrice
                                          )
                                            ? 0
                                            : shopsPricesArray[index].NightPrice
                                        }
                                        onChange={(e) =>
                                          handleShopPriceChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {isNaN(shopsPricesArray[index].QTY)
                                        ? 0
                                        : shopsPricesArray[index].QTY}
                                    </td>
                                    {/*   <td>
                                      <input
                                        type="number"
                                        min="1"
                                        step="1"
                                        max="10000"
                                        className="form-control form-control-sm"
                                        name="MinStockLevel"
                                        value={
                                          isNaN(
                                            shopsPricesArray[index]
                                              .MinStockLevel
                                          )
                                            ? 0
                                            : shopsPricesArray[index]
                                                .MinStockLevel
                                        }
                                        onChange={(e) =>
                                          handleShopPriceChange(e, index)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min="1"
                                        step="1"
                                        max="10000"
                                        className="form-control form-control-sm"
                                        name="MinOrderQTY"
                                        value={
                                          isNaN(
                                            shopsPricesArray[index].MinOrderQTY
                                          )
                                            ? 0
                                            : shopsPricesArray[index]
                                                .MinOrderQTY
                                        }
                                        onChange={(e) =>
                                          handleShopPriceChange(e, index)
                                        }
                                      />
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row"></div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Supplier Product Information</h3>
                  </div>
                  <div className="card-body">
                    <div className="card">
                      <div className="card-body p-0">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Code</th>
                              <th>Pack Size</th>
                              <th>Cost</th>
                              <th>RSP</th>
                              <th>Last Sync</th>
                            </tr>
                          </thead>
                          <tbody>
                            {supplierProducts?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.supplier_name}</td>
                                  <td>{item.supplier_plu}</td>
                                  <td>{item.units_per_case}</td>
                                  <td>{item.case_cost}</td>
                                  <td>{item.rsp}</td>
                                  <td>{item.last_modified}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-footer mt-4">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <input
                  type="submit"
                  name="btnSubmit"
                  value="Submit"
                  className="btn btn-primary float-right mr-3"
                />
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
}
