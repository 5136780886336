import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import axios from "axios";
import Utils from "../../Utils";
import { Toast } from "primereact/toast";
import "../custom-styles/filter-styles.css";

export default function Products() {
  const toast = useRef(null);
  const [products, setProducts] = React.useState();
  const [productGroupsArray, setProductGroupsArray] = React.useState([]);
  const [productGroup, setProductGroup] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      product_barcode: { value: "", matchMode: "contains" },
      product_name: { value: "", matchMode: "contains" },
      product_plu: { value: "", matchMode: "contains" },
      department_name: { value: "", matchMode: "contains" },
      sub_department_name: { value: "", matchMode: "contains" },
      product_selling_price: { value: "", matchMode: "contains" },
      group_id: { value: "", matchMode: "contains" },
    },
  });

  let loadLazyTimeout = null;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductGroupChange = (e) => {
    console.log("formData." + e.target.name, e.target.value);

    setProductGroup(e.target.value);
    let tst = lazyParams;
    tst.filters.group_id = { value: e.target.value, matchMode: "contains" };

    setLazyParams((current) => {
      return {
        ...current,
        filters: {
          ...current.filters,
          group_id: { ...current.filters.group_id, value: e.target.value },
        },
      };
    });
    console.log("lazyParams", lazyParams);
    // loadLazyData();
  };

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    axios
      .post(
        Utils.apiBaseURL + "products/getall",
        { lazyParams: JSON.stringify(lazyParams) },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("total", response.data.totalrecords);
        setProducts(response.data.products);
        setTotalRecords(response.data.totalrecords);
        getGroups();
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: ex.response.data.msg,
            life: 10000,
          });
          console.log(ex.response);
        } else if (ex.request) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: ex.request,
            life: 10000,
          });
          console.log(ex.request);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: ex.message,
            life: 10000,
          });
          console.log("Error", ex.message);
        }
      });
  };
  const onPage = (event) => {
    console.log(event);
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  const getGroups = () => {
    setLoading(true);
    axios
      .post(
        Utils.apiBaseURL + "products/getallgroups",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setProductGroupsArray(response.data.groups);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Link
          className=""
          to={{
            pathname: "/add-edit-products",
            rowData: rowData,
            action: "edit",
          }}
        >
          Edit
        </Link>
      </>
    );
  };
  const lastSoldTemplate = (rowData) => {
    var lstsldadte = rowData.last_sold_date;
    return moment(lstsldadte)
      .format("DD-MM-YY HH:mm")
      .toString()
      .replace("Invalid date", "");
  };

  const costBodyTemplate = (rowData) => {
    var CaseSize = rowData.product_unit_per_case;
    if (CaseSize <= 0) {
      CaseSize = 1;
    }

    var ProductCost = rowData.product_cost_per_case / CaseSize;
    var profit = rowData.product_selling_price - ProductCost;
    var GrossMargin = (profit / rowData.product_selling_price) * 100;
    return (Math.round(GrossMargin * 100) / 100).toFixed(2) + "%";
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "quantity":
      case "product_selling_price":
        rowData[field] = newValue;
        confirmPriceChange(rowData["id"], newValue);

        break;

      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options) => {
    if (options.field === "product_selling_price") return priceEditor(options);
    else return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="GBP"
        locale="en-UK"
      />
    );
  };
  const confirmPriceChange = (id, newValue) => {
    confirmDialog({
      message: "Are you sure you want to Change the Price ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => acceptFunc(id, newValue),
      reject: () => rejectFunc(),
    });
  };

  const acceptFunc = (id, newValue) => {
    //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    console.log("ID :", id, "Value :", newValue);
    axios
      .post(
        Utils.apiBaseURL + "products/updateprice",
        { product_id: id, ProductSellingPrice: newValue },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
          life: 3000,
        });
      })
      .catch((ex) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unable to Update the Price!!",
          life: 3000,
        });
        loadLazyData();
      });
  };

  const rowClass = (data) => {
    return {
      "text-sm": data.product_barcode.length > 0,
    };
  };

  const rejectFunc = () => {
    // toast.current.show({
    //   severity: "warn",
    //   summary: "Rejected",
    //   detail: "You have rejected",
    //   life: 3000,
    // });
  };
  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">Products</h3>
                  <select
                    className="form-control"
                    name="SubDepartment"
                    value={productGroup}
                    onChange={handleProductGroupChange}
                    required
                  >
                    <option value={""}>--SELECT--</option>
                    {productGroupsArray.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.group_name}
                        </option>
                      );
                    })}
                  </select>
                  <Link to="/add-edit-products">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                    >
                      Add Products
                    </button>
                  </Link>
                </div>

                <div className="card-body">
                  <DataTable
                    value={products}
                    rowClassName={rowClass}
                    showGridlines
                    resizableColumns
                    columnResizeMode="fit"
                    size="small"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    paginator
                    lazy
                    first={lazyParams.first}
                    rows={lazyParams.rows}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    loading={loading}
                    onSort={onSort}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                  >
                    {/* <Column field="id" header="ID" sortable filter /> */}
                    <Column
                      field="product_barcode"
                      header="Barcode"
                      sortable
                      filter
                    />
                    <Column field="product_plu" header="PLU" sortable filter />
                    <Column
                      field="product_name"
                      header="Name"
                      sortable
                      filter
                    />
                    <Column
                      field="department_name"
                      header="Department"
                      sortable
                      filter
                    />
                    <Column
                      field="product_unit_per_case"
                      header="Unit"
                      align="center"
                      sortable
                    />
                    <Column
                      field="product_cost_per_case"
                      header="Cost"
                      align="right"
                      sortable
                    />
                    <Column
                      body={costBodyTemplate}
                      header="Margin"
                      sortable
                      align="right"
                      style={{
                        width: "50px !important",
                      }}
                    />
                    <Column
                      body={lastSoldTemplate}
                      header="Last Sold"
                      align="center"
                      sortable
                      filter
                    />
                    <Column
                      field="product_selling_price"
                      header="Price"
                      align="right"
                      sortable
                      filter
                      editor={(options) => cellEditor(options)}
                      onCellEditComplete={onCellEditComplete}
                    />
                    <Column
                      headerStyle={{ width: "4rem", textAlign: "center" }}
                      bodyStyle={{ textAlign: "center", overflow: "visible" }}
                      body={actionBodyTemplate}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
