import { React, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Utils from "../../Utils";
import { Toast } from "primereact/toast";

export default function AddEditSupplier() {
  const toast = useRef(null);
  const location = useLocation();

  const [formData, setFormData] = useState({
    supplier_code: "",
    supplier_name: "",
    supplier_description: "",
    supplier_balance_due: "",
    supplier_credit_limit: "",
    supplier_address: "",
    supplier_phone: "",
    supplier_mobile: "",
    supplier_fax: "",
    supplier_email: "",
    supplier_website: "",
  });

  const [loading, setLoading] = useState(false);
  const [editSupplierID, setEditSupplierID] = useState();

  const handleChange = (e) => {
    const inputValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: inputValue,
      };
    });
  };

  //executed first
  useEffect(() => {
    console.log("location.rowData", location.rowData);
    console.log("location.action", location.action);

    //for edit mode
    if (
      location != null &&
      location.action != null &&
      location.rowData != null &&
      parseInt(location.rowData.id)
    ) {
      setEditSupplierID(location.rowData.uuid);
    } else {
      // add mode
    }
  }, []);

  useEffect(() => {
    getSupplierByUUID(editSupplierID);
  }, [editSupplierID]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const getSupplierByUUID = (_id) => {
    setLoading(true);

    axios
      .post(
        Utils.apiBaseURL + "suppliers/getbyuuid",
        { uuid: _id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Supplier received", response);
        setFormData(response.data.supplier);
      })
      .catch((ex) => {
        setLoading(false);
        if (ex.response) {
          console.log(ex.response);
        } else if (ex.request) {
          console.log(ex.request);
        } else {
          console.log("Error", ex.message);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var apiURL = "";
    if (
      location != null &&
      location.action != null &&
      location.action === "edit"
    ) {
      apiURL = Utils.apiBaseURL + "suppliers/editbyuuid";
    } else {
      apiURL = Utils.apiBaseURL + "suppliers/add";
    }

    var localFormData = formData;

    console.log("localFormData", localFormData);

    setLoading(true);

    axios
      .post(
        apiURL,
        { formData, uuid: editSupplierID },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("API response", response.status);
        if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.msg,
            life: 3000,
          });

          if (editSupplierID === 0) {
            setTimeout(window.location.reload(false), 7000);
          }
          setLoading(false);
        } else {
          alert(response.data.error);
        }
      })
      .catch((ex) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: ex.message,
          life: 3000,
        });
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1> Supplier Information</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Add Supplier</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Supplier Information</h3>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Supplier Code</label>
                          <input
                            className="form-control"
                            name="supplier_code"
                            value={formData.supplier_code}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Supplier Name</label>
                          <input
                            className="form-control"
                            name="supplier_name"
                            value={formData.supplier_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Supplier Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="supplier_description"
                        value={formData.supplier_description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Balance Due
                          </label>
                          <input
                            className="form-control rounded-0"
                            name="supplier_balance_due"
                            value={formData.supplier_balance_due}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Credit Limit
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-0"
                            name="supplier_credit_limit"
                            value={formData.supplier_credit_limit}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Contact Information</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Supplier Address</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="supplier_address"
                        value={formData.supplier_address}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Phone
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-0"
                            name="supplier_phone"
                            value={formData.supplier_phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Mobile
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-0"
                            name="supplier_mobile"
                            value={formData.supplier_mobile}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputRounded0">
                            Supplier Fax
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-0"
                            name="supplier_fax"
                            value={formData.supplier_fax}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Supplier Email</label>
                          <input
                            type="text"
                            className="form-control"
                            name="supplier_email"
                            value={formData.supplier_email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Supplier Website</label>
                          <input
                            type="text"
                            className="form-control"
                            name="supplier_website"
                            value={formData.supplier_website}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-footer py-2">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <input
                  type="submit"
                  name="btnSubmit"
                  value="Save"
                  className="btn btn-primary float-right mr-3"
                />
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
}
