import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import axios from "axios";
import Utils from "../../Utils";

import "../custom-styles/filter-styles.css";

export default function Promotions() {
  const [error, setError] = React.useState();
  const [promotions, setPromotions] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageInputTooltip, setPageInputTooltip] = React.useState(
    "Press 'Enter' key to go to this page."
  );
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 100,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      Promotion_Type: { value: "", matchMode: "contains" },
      Promotion_Name: { value: "", matchMode: "contains" },
      Promotion_Price: { value: "", matchMode: "contains" },
      Promotion_Start_Date: { value: "", matchMode: "contains" },
      Promotion_End_Date: { value: "", matchMode: "contains" },
      Promotion_Discount: { value: "", matchMode: "contains" },
      Promotion_Free_QTY: { value: "", matchMode: "contains" },
    },
  });

  let loadLazyTimeout = null;

  const rowClass = (data) => {
    return {
      "text-sm": 1 === 1,
    };
  };

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      axios
        .post(
          Utils.apiBaseURL + "promotions/getall",
          { lazyParams: JSON.stringify(lazyParams) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setPromotions(response.data.promotions);
          setTotalRecords(response.data.totalrecords);
          setLoading(false);
        })
        .catch((ex) => {
          setLoading(false);
          if (ex.response) {
            console.log(ex.response);
          } else if (ex.request) {
            console.log(ex.request);
          } else {
            console.log("Error", ex.message);
          }
        });
    }, Math.random() * 1000 + 250);
  };
  const onPage = (event) => {
    setLazyParams(event);
  };

  const onSort = (event) => {
    setLazyParams(event);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  // const actionBodyTemplate = () => {
  //   return <Button type="button" icon="pi pi-cog"></Button>;
  // };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Link
          to={{
            pathname: "/add-edit-promotions",
            rowData: rowData,
            action: "edit",
          }}
        >
          Edit
        </Link>
      </>
    );
  };
  const paginatorLeft = (
    <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  );
  const paginatorRight = (
    <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  );

  const [promotionTypes] = useState([
    { name: "Buy N get N Free", id: "1" },
    { name: "Buy N for X.XX amount", id: "2" },
    { name: "Was X.XX Now X.XX", id: "3" },
    { name: "Bulk Buy Discount/Step Promotion", id: "4" },
    { name: "Any Items for X.XX", id: "5" },
    { name: "Combo/Meal Deal Promotion", id: "6" },
  ]);

  const promotionTypesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>
          {option.id}-{option.name}
        </span>
      </div>
    );
  };
  const formatDate = (date) => {
    // Customize the date format here, for example:
    return new Date(date).toLocaleDateString("en-UK", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const promotionTypesRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={promotionTypes}
        itemTemplate={promotionTypesItemTemplate}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
          console.log(e.value);
        }}
        optionLabel="name"
        placeholder={options.value != null ? options.value.name : ""}
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  return (
    <>
      <section className="content pt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title font-weight-bold">Promotions</h3>
                  <Link to="/add-edit-promotions">
                    <button
                      type="button"
                      className="btn btn-primary float-right"
                    >
                      Add Promotions
                    </button>
                  </Link>
                </div>

                <div className="card-body">
                  <DataTable
                    value={promotions}
                    rowClassName={rowClass}
                    showGridlines
                    resizableColumns
                    columnResizeMode="fit"
                    size="small"
                    responsiveLayout="scroll"
                    paginator
                    lazy
                    first={lazyParams.first}
                    rows={lazyParams.rows}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    onFilter={onFilter}
                    filters={lazyParams.filters}
                    loading={loading}
                    filterDisplay="row"
                    onSort={onSort}
                    sortField={lazyParams.sortField}
                    sortOrder={lazyParams.sortOrder}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                  >
                    <Column field="Promotion_ID" header="ID" sortable filter />
                    <Column
                      field="Promotion_Type"
                      header="Type"
                      filter
                      filterElement={promotionTypesRowFilterTemplate}
                      sortable
                    />
                    <Column
                      field="Promotion_Name"
                      header="Name"
                      sortable
                      filter
                    />
                    <Column
                      field="Promotion_Price"
                      header="Price"
                      sortable
                      filter
                    />
                    <Column
                      field="Promotion_Start_Date"
                      header="Start Date"
                      body={(rowData) =>
                        moment(
                          rowData.Promotion_Start_Date.replace(
                            "T",
                            " "
                          ).replace("Z", "")
                        ).format("DD-MM-YYYY")
                      }
                      sortable
                      filter
                    />
                    <Column
                      field="Promotion_End_Date"
                      header="End Date"
                      body={(rowData) =>
                        moment(
                          rowData.Promotion_End_Date.replace("T", " ").replace(
                            "Z",
                            ""
                          )
                        ).format("DD-MM-YYYY")
                      }
                      sortable
                      filter
                    />
                    <Column
                      field="Promotion_Discount"
                      header="Discount"
                      sortable
                      filter
                    />
                    <Column
                      field="Promotion_Free_QTY"
                      header="Free QTY"
                      sortable
                      filter
                    />
                    <Column
                      headerStyle={{ width: "4rem", textAlign: "center" }}
                      bodyStyle={{ textAlign: "center", overflow: "visible" }}
                      body={actionBodyTemplate}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
